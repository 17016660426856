
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.navbar {
  width: 100%;
  padding: 32px 8px 8px;
  height: 85px;
  background-color: $white-0;
  box-shadow: $box-shadow-3;

  &-content {
    min-height: 45px;
  }
}
