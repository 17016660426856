
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.modal {
  height: max-content;
  width: max-content;
  min-width: min(300px, 85%);
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  border-radius: 5px;
  background: $white-0;
  box-shadow: $box-shadow-2;

  &-close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  // @include mq-max($sm) {
  //   max-width: 90%;
  // }
}
