
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.dashboard-header {
  position: relative;
  width: 100%;
  min-height: 160px;
  padding: 32px;
  background: $company-main;

  .left-circles {
    position: absolute;
    left: -4px;
    top: 80px;
  }

  .right-circle {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  .white-box {
    height: 37px;
    position: absolute;
    left: 24px;
    bottom: 0px;
    right: 24px;
    background: $white-1;
    border-radius: 10px 10px 0px 0px;
  }

  .dashboard-avatar {
    position: absolute;
    top: 28px;
    right: 24px;
  }

  .dashboard-title {
    @include text-ellipsis($line-clamp: 2);
  }
}
