
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.base-product-card {
  &-title {
    min-height: 36px;
    @include text-ellipsis(2);
  }
}
