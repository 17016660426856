
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.base-layout {
  display: flex;
  flex-direction: column;
  padding: 32px;
  row-gap: 80px;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  height: max-content;
}
