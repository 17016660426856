
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.certify-search-products-list {
  height: 100%;
  width: 100%;

  .test {
    width: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .card-title {
    min-height: 36px;
    @include text-ellipsis(2);
  }
}
