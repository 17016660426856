
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.layout-with-navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &-main {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    &.content-padding {
      padding: 16px;
    }

    &.disable-scroll {
      overflow: hidden;
    }
  }
}
