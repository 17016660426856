
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.dashboard {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  &-certify-section {
    padding: 36px 0;

    .certify-btn {
      width: 100%;
      max-width: 220px;
    }
  }

  &-main {
    padding-top: 4px;
    overflow-y: auto;
  }
}
