
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.button {
  font-weight: $bold;
  font-size: 14px;
  letter-spacing: 1.25%;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color;
  border-radius: 12px;
  box-shadow: $box-shadow-0;

  &:not(:disabled):active {
    box-shadow: $box-shadow-4;
  }

  &-primary {
    color: $primary;
    background-color: $accent-default;

    &:not(:disabled):active {
      background-color: $accent-pressed;
    }

    &:disabled {
      color: $gray-3;
      background-color: $gray-2;
    }
  }

  &-secondary {
    color: $white-0;
    background-color: $gray-5;

    &:not(:disabled):active {
      background-color: $gray-4;
    }

    &:disabled {
      color: $white-6;
      background-color: $gray-6;
    }
  }

  &-normal {
    padding: 16px;
    min-width: 220px;
    height: 60px;
  }

  &-small {
    padding: 12px;
    min-width: 150px;
    height: 45px;
  }

  &.full-width {
    width: 100%;
  }
}
