
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

hr,
a,
button,
div,
span,
label,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
}

h1 {
  font-weight: $bold;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0;
}

h2 {
  font-weight: $bold;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0;
}

h3 {
  font-weight: $bold;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0;
}

p,
.p0 {
  font-weight: $medium;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0;
}

.p1 {
  font-weight: $medium;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: 0;
}

.p2 {
  font-weight: $bold;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 1.25%;
}

// VARIABLE-BASED TOOL
.ultra-light {
  font-weight: $ultra-light;
}
.light {
  font-weight: $light;
}
.regular {
  font-weight: $regular;
}
.medium {
  font-weight: $medium;
}
.bold {
  font-weight: $bold;
}
.extra-bold {
  font-weight: $extra-bold;
}

.lh-0 {
  line-height: $lh-0;
}
.lh-1 {
  line-height: $lh-1;
}
.lh-2 {
  line-height: $lh-2;
}
.lh-3 {
  line-height: $lh-3;
}
.lh-4 {
  line-height: $lh-4;
}
.lh-5 {
  line-height: $lh-5;
}
.lh-6 {
  line-height: $lh-6;
}

.ls-1 {
  letter-spacing: $ls-1;
}
.ls-2 {
  letter-spacing: $ls-2;
}
.ls-3 {
  letter-spacing: $ls-3;
}
.ls-4 {
  letter-spacing: $ls-4;
}

.fs-0 {
  font-size: $fs-0;
}
.fs-1 {
  font-size: $fs-1;
}
.fs-2 {
  font-size: $fs-2;
}
.fs-3 {
  font-size: $fs-3;
}
.fs-4 {
  font-size: $fs-4;
}
.fs-5 {
  font-size: $fs-5;
}
.fs-6 {
  font-size: $fs-6;
}
.fs-7 {
  font-size: $fs-7;
}
.fs-8 {
  font-size: $fs-8;
}

.primary {
  color: $primary;
}
.primary-bg {
  background-color: $primary;
}

.accent-default {
  color: $accent-default;
}
.accent-default-bg {
  background-color: $accent-default;
}

.accent-pressed {
  color: $accent-pressed;
}
.accent-pressed-bg {
  background-color: $accent-pressed;
}

.dark-blue {
  color: $dark-blue;
}
.dark-blue-bg {
  background-color: $dark-blue;
}

.blue {
  color: $blue;
}
.blue-bg {
  background-color: $blue;
}

.company-main {
  color: $company-main;
}
.company-main-bg {
  background-color: $company-main;
}

.white-0 {
  color: $white-0;
}
.white-0-bg {
  background-color: $white-0;
}

.white-1 {
  color: $white-1;
}
.white-1-bg {
  background-color: $white-1;
}

.white-2 {
  color: $white-2;
}
.white-2-bg {
  background-color: $white-2;
}

.white-3 {
  color: $white-3;
}
.white-3-bg {
  background-color: $white-3;
}

.white-4 {
  color: $white-4;
}
.white-4-bg {
  background-color: $white-4;
}

.white-5 {
  color: $white-5;
}
.white-5-bg {
  background-color: $white-5;
}

.white-6 {
  color: $white-6;
}
.white-6-bg {
  background-color: $white-6;
}

.gray-0 {
  color: $gray-0;
}
.gray-0-bg {
  background-color: $gray-0;
}

.gray-1 {
  color: $gray-1;
}
.gray-1-bg {
  background-color: $gray-1;
}

.gray-2 {
  color: $gray-2;
}
.gray-2-bg {
  background-color: $gray-2;
}

.gray-3 {
  color: $gray-3;
}
.gray-3-bg {
  background-color: $gray-3;
}

.gray-4 {
  color: $gray-4;
}
.gray-4-bg {
  background-color: $gray-4;
}

.gray-5 {
  color: $gray-5;
}
.gray-5-bg {
  background-color: $gray-5;
}

.gray-6 {
  color: $gray-6;
}
.gray-6-bg {
  background-color: $gray-6;
}

.success {
  color: $success;
}
.success-bg {
  background-color: $success;
}

.error {
  color: $error;
}
.error-bg {
  background-color: $error;
}

.warning {
  color: $warning;
}
.warning-bg {
  background-color: $warning;
}

// .gradient-text {
//   color: $primary;
//   background: $primary;
//   background-image: $gradient-2;
//   background-clip: text;
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   -moz-background-clip: text;
//   -moz-text-fill-color: transparent;
// }
// VARIABLE-BASED TOOL
