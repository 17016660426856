// COLORS
$primary: #2c2c2c;
$accent-default: #ffa800;
$accent-pressed: #ffb933;

$dark-blue: #35477e;
$blue: #5286ef;
$company-main: #3d4a5e;

$white-0: #ffffff;
$white-1: #f2f2f2;
$white-2: #ececec;
$white-3: #f9f9f9;
$white-4: #f2f4f5;
$white-5: #e9ecee;
$white-6: #cacaca;

$gray-0: #afb1b2;
$gray-1: #696969;
$gray-2: #c7c7c7;
$gray-3: #757575;
$gray-4: #929292;
$gray-5: #696969;
$gray-6: #8a8a8a;

$success: #1dcf00;
$warning: #ffa800;
$error: #dd4248;
// COLORS

// BOX-SHADOWS
$box-shadow-0: 0px 3px 5px 0px rgba(153, 153, 153, 0.2), 0px 1px 18px 0px rgba(153, 153, 153, 0.12),
  0px 6px 10px 0px rgba(153, 153, 153, 0.14);
$box-shadow-1: 0px 1px 5px 0px rgba(153, 153, 153, 0.2), 0px 3px 1px 0px rgba(153, 153, 153, 0.12),
  0px 2px 2px 0px rgba(153, 153, 153, 0.14);
$box-shadow-2: 0px 6px 10px 0px rgba(0, 0, 0, 0.14);
$box-shadow-3: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
$box-shadow-4: 0px 6px 5px 0px rgba(153, 153, 153, 0.25);
// BOX-SHADOWS

// GRADIENTS
$gradient-0: 0;

// GRADIENTS

// BREAKPOINTS
$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1140px;
$xxl: 1440px;

$breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl
);
// BREAKPOINTS

// dX
$size-dx: 8px;
// dX

// SIZES
$size-1: $size-dx;
$size-2: $size-dx * 2;
$size-3: $size-dx * 3;
$size-4: $size-dx * 4;
$size-5: $size-dx * 5;
$size-6: $size-dx * 6;
$size-7: $size-dx * 7;
$size-8: $size-dx * 8;
$size-9: $size-dx * 9;
$size-10: $size-dx * 10;

$size-variants: (
  1: $size-1,
  2: $size-2,
  3: $size-3,
  4: $size-4,
  5: $size-5,
  6: $size-6,
  7: $size-7,
  8: $size-8,
  9: $size-9,
  10: $size-10
);
// SIZES

$display-variants: (
  b: block,
  f: flex,
  n: none
);

// FONT WEIGHTS
$ultra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$bold: 600;
$extra-bold: 700;
// FONT WEIGHTS

// LINE HEIGHTS
$lh-0: 110%;
$lh-1: 120%;
$lh-2: 130%;
$lh-3: 140%;
$lh-4: 150%;
$lh-5: 160%;
$lh-6: 170%;
// LINE HEIGHTS

// LETTER SPACING
$ls-1: -0.02em;
$ls-2: -0.01em;
$ls-3: 0.01em;
$ls-4: 0.02em;
// LETTER SPACING

// FONT-SIZES
$fs-0: 48px;
$fs-1: 38px;
$fs-2: 28px;
$fs-3: 20px;
$fs-4: 18px;
$fs-5: 15px;
$fs-6: 14px;
$fs-7: 12px;
$fs-8: 11px;
// FONT-SIZES
