
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.codes-scanner {
  position: relative;
  display: flex;
  flex: 1 1 0;
  flex-basis: 0;
  width: 100%;
  height: auto;
  overflow-y: hidden;

  .camera-video {
    flex: 1;
    width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: cover;
    background: rgba(black, 0.3);
  }

  .camera-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .camera-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 265px;
    height: 263px;
    border-radius: 8px;
    outline: rgba(0, 0, 0, 0.5) solid 90vmax;
  }
}
