
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
* {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  overscroll-behavior-y: contain;
}

html {
  scroll-behavior: smooth;
  font-size: 14px;
  background-color: $white-1;
  color: $primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
section {
  height: max-content;
  width: 100%;
}

#root {
  height: 100dvh;
  min-height: 100dvh;

  > * {
    width: 100%;
  }
}

a {
  color: unset;
  text-decoration: none;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

input {
  border: none;
  outline: none;
}

hr {
  border: none;
}

#overlay {
  &-default {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1000;
  }

  &-notification {
    position: fixed;
    height: max-content;
    width: max-content;
    left: 0px;
    bottom: 0px;
    z-index: 1001;
  }

  .drawer {
    z-index: 1002;
  }
  .dialog {
    z-index: 2001;
  }
  .modal {
    z-index: 3001;
  }
  .message {
    z-index: 4001;
  }
  .notification {
    z-index: 5001;
  }
  .toast {
    z-index: 6001;
  }
}

button,
[role="button"],
label[for],
input[type="radio"],
input[type="checkbox"],
input[type="reset"],
input[type="button"],
input[type="submit"],
input[type="file"]::-webkit-file-upload-button,
.notification,
.toast {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
