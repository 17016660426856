
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.dots {
  position: absolute;
  right: 0px;
  transform: translateX(100%);
  bottom: 0px;
}
