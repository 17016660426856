
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.input {
  width: 100%;
  background-color: $white-5;
  padding: 12px 16px;
  border-radius: 32px;
  font-size: 12px;
  font-weight: $medium;
  color: $primary;
  border: 1px solid $gray-0;
  -webkit-transition: background-color 0.25s ease-in-out; //Safari
  transition: background-color 0.25s ease-in-out;

  &::placeholder {
    color: $gray-0;
  }

  &:focus {
    color: $primary;
    background-color: $white-3;
    border: 1px solid $gray-0;
  }
}
