
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.clickable-card {
  background-color: $white-0;
  box-shadow: $box-shadow-1;
  display: flex;
  align-items: center;
  border-radius: 7px;
  min-height: 80px;
  width: 100%;
  transition: background-color;
  position: relative;

  &-status-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  &-status-icon-success {
    position: absolute;
    right: 4px;
    bottom: 4px;
  }

  &-base {
    border-bottom: 2.5px solid none;
  }

  &-loading {
    border-bottom: 2.5px solid $accent-default;
    opacity: 0.55;
  }

  &-success {
    border-bottom: 2.5px solid $success;
  }

  &-error {
    border-bottom: 2.5px solid $error;
  }

  &-certified {
    border-bottom: 2.5px solid none;
    opacity: 0.6;
  }

  &:not(.clickable-disabled):active {
    background-color: $white-2;
  }

  &-padding-normal {
    padding: 24px 16px;
  }

  &-padding-small {
    padding: 8px;
  }
}
