
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.notification {
  $notification-posiotion: 30px;

  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 100%);
  display: flex;
  align-items: center;
  border-radius: 15px;
  background: $white-0;
  box-shadow: $box-shadow-2;
  min-height: 62px;
  min-width: min(500px, 90vw);
  white-space: pre-line;

  &-starting {
    animation: appear 0.5s cubic-bezier(0.08, 0, 0.19, 1) forwards;
  }

  &-idle {
    transform: translate(-50%, -$notification-posiotion);
  }

  &-stopping {
    animation: disappear 0.8s cubic-bezier(0.21, 0, 1, 0.04) forwards;
  }

  &-clickable {
    cursor: pointer;

    .notification-content {
      &:active {
        background-color: $white-2;
        border-radius: 15px 0 0 15px;
      }
    }
  }

  &-content {
    width: 100%;
    padding: 12px 8px 12px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: background-color 0.25s ease-out;

    .content-icon {
    }

    .content-message {
      flex: 1 0 0;
      color: $gray-1;
    }
  }

  &-button {
    min-width: 55px;
    max-width: 55px;
    align-self: stretch;
    transition: background-color 0.25s ease-out;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      width: 2px;
      top: 0;
      bottom: 0;
      background-color: $white-2;
    }

    &:active {
      background-color: $white-2;
      border-radius: 0 15px 15px 0;

      svg path {
        fill: $accent-default;
      }
    }
  }

  @keyframes appear {
    0% {
      transform: translate(-50%, 100%);
    }
    100% {
      transform: translate(-50%, -$notification-posiotion);
    }
  }

  @keyframes disappear {
    0% {
      transform: translate(-50%, -$notification-posiotion);
    }
    100% {
      transform: translate(-50%, 100%);
    }
  }
}
