
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.avatar {
  $circle-size: var(--circle-size);

  &-image,
  &-placeholder {
    height: $circle-size;
    width: $circle-size;
    flex-shrink: 0;
    border-radius: 50%;
    position: relative;
  }

  &-placeholder {
    background-color: $primary;
  }
}
