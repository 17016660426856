
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.infiniteScroll {
  &,
  &-children {
    height: 100%;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-children,
  &-pullDown {
    transition: transform 0.2s cubic-bezier(0, 0, 0.31, 1);
  }

  &-pullDown {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    visibility: hidden;
    > div {
      display: none;
    }
  }

  &-dragging {
    // Hide PullMore content is treshold breached
    &.infiniteScroll-pullDown-tresholdBreached {
      .infiniteScroll-pullDown-pullMore {
        display: none;
      }
    }

    // Otherwize, display content
    .infiniteScroll-pullDown-pullMore {
      display: block;
    }
  }

  &-pullDown-tresholdBreached {
    // Force opacity to 1 is pull down trashold breached
    &-pullDown {
      opacity: 1 !important;
    }

    // And display loader
    .infiniteScroll-pullDown-loading {
      display: block;
    }
  }

  &-fetchMore {
    height: 76px;
    padding: 24px 0;
    display: flex;
    align-items: center;
  }

  &-loader {
    margin: 0 auto;
    text-align: center;
  }
}
