
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.action-icon {
  width: 45px;
  height: 45px;

  .content {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease-out;
  }

  &:not(&:disabled):active {
    .content {
      background-color: $white-1;

      svg path {
        color: $accent-default;
        stroke: $accent-default;
      }
    }
  }
}
