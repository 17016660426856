
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.settings {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;

  &-main {
    flex: 1;
  }

  &-main-user {
  }

  &-main-company {
    .company-logo {
      border-radius: 50%;
    }
  }

  &-buttons {
    height: 180px;
  }

  .app-version {
    position: absolute;
    bottom: 16px;
    left: 16px;
  }

  .card-white {
    padding: 16px;
    border-radius: 7px;
    background: $white-0;
  }
}
