
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.countdown-timer {
  p {
    color: $gray-1;
    font-size: 12px;
    font-weight: $regular;
    line-height: 18px;
    letter-spacing: 0.5px;
  }
}
