
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.cert-status-card {
  background-color: $white-0;
  box-shadow: $box-shadow-1;
  border-radius: 7px;
  min-height: 160px;
  padding: 32px 16px;
}
