
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.toast {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  border-radius: 5px;
  background-color: $white-0;
  box-shadow: $box-shadow-2;
  min-width: min(300px, 90vw);
  min-height: 52px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  white-space: break-spaces;

  &-base {
    justify-content: center;
  }

  &-loading {
  }

  &-error {
  }

  &-icon {
    display: flex;
  }

  &-clickable {
    cursor: pointer;

    &:active {
      background-color: $white-2;
    }
  }
}
