
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.certify-scan-product {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;

  &-button {
    height: 180px;
  }
}
