
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.certs-list {
  .certs-card {
    &-title {
      min-height: 36px;
      @include text-ellipsis(2);
    }
  }
}
