
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.auth-access-code {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  &-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    max-width: 360px;
    text-align: center;
    margin: 0 auto;
  }

  .card-white {
    padding: 32px 24.5px;
    border-radius: 7px;
    background: $white-0;
  }
}
