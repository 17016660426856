
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.auth-qr-code {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;

  &-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    max-width: 360px;
    text-align: center;
    margin: 0 auto;
  }
}
