
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.dashboard-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &-main {
    padding: 0 16px;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
  }

  .disable-scroll {
    overflow: hidden;
  }
}
