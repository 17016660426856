
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.perminnsions-error {
  flex: 1 1 0;

  .error-message {
    max-width: 300px;
    white-space: pre-wrap;
  }

  .perminnsions-error-icon-box {
    position: relative;
    background-color: $white-2;
    border-radius: 14px;
    width: 269px;
    height: 267px;

    .error-camera-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
