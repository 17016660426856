
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.certify-search-product {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 16px;

  .card-white {
    padding: 32px 24.5px;
    border-radius: 7px;
    background: $white-0;
  }

  &-main {
    overflow: auto;
  }
}
