
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.certify {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;

  &-steps {
    flex: 1;
    padding: 16px 0;
  }

  &-buttons {
    padding: 32px 0;
    min-height: 200px;
  }
}
