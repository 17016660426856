
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.backdrop-wrapper {
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;

  .backdrop {
    height: 100%;
    width: 100%;
    background-color: rgba($primary, 0.5);
  }

  &.open {
    display: block;
  }
}
