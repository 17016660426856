
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.certify-product-card {
  .card-title {
    min-height: 36px;
    @include text-ellipsis(2);
  }
}
