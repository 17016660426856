
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.action-button {
  color: $blue;
  font-weight: $bold;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 15px;
  transition: color 0.25s ease-out;

  &:not(:disabled):active {
    color: $accent-default;
  }

  &.full-width {
    width: 100%;
  }
}
