
          @import "./src/scss/variables.scss";
          @import "./src/scss/mixins.scss";
        
.certs-product-card {
  &-timer {
    position: absolute;
    right: 40px;
    bottom: 10px;
  }
}
